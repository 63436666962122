 .main-nav {
 	position: relative;
 	width: 100%;
 	flex-shrink: 0;
 	background-color: $main-nav-bg-color;
 	color: $main-nav-color;
 	padding: 0;
 	@include pad($main-nav-bg-color, left);
 	order: 2;

 	&::after {
 		position: absolute;
 		content: "";
 		left: 100%;
 		width: 99999em;
 		top: 0;
 		bottom: 0;
 		background-color: $main-nav-bg-color;
 	}

 	@include min(600px) {
		order: 1;
		padding-left: 8%;
	}

	@include min(800px) {
		order: 0;
		width: calc(100% - 200px);
		flex: 1;
	}

 	&__heading {
 		margin-top: .5em;
 	}

 	.nav {
 		@include flex(df, jcfs, fww);
 		padding: 0;
 		margin: 20px -10px 0;

 		&__item {
 			width: 100%;
 			display: inline-block;
 			flex-grow: 0;
 			margin: 0 10px 20px;
 			text-align: center;
 			font-weight: 500;
 			font-size: rem(26px);
 			font-family: $font-headings;

 			@include min(600px) {
 				width: auto;
 				min-width: 200px;
 				max-width: 300px;
 				display: inline-flex;

 				.nav__link {
 					width: 100%;
 				}
 			}

 			@include link-over(&) {
 				border-right: 0;
 			}

 			.info-hidden,
 			.info-concept {
 				align-self: center;
 			}
 		}

 		&__description {
 			display: none;
 		}

 		&__link {
 			display: block;
 			padding: .25em .5em;
 			overflow: hidden;
 			position: relative;
 			background-color: $main-nav-button-bg;
 			transition: background-color 0.3s;

 			@include link(&) {
 				color: $main-nav-color;
 				text-decoration: none;
 			}

 			@include link-over(&) {
 				color: $main-nav-button-color-hover;
 				background-color: $main-nav-button-bg-hover;
 			}

 			@include min(600px) {

 				&,
 				&.-active {
 					@include link(&) {
 						@include flex(df, jcc, aic);
 					}
 				}
 			}

 			@include min(1280px) {
 				padding: .35em 1em;
 			}

 			&.-active {
 				cursor: default;
 				pointer-events: none;
 			}

 			&.-active,
 			&.-active-parent {
 				@include link(&) {
 					text-decoration: none;
 					color: $main-nav-button-color-hover;
 					background-color: $main-nav-button-bg-hover;

 					&::after {
 						content: normal;
 					}
 				}
 			}
 		}
 	}
 }
