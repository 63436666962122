.contact-form {
	margin: 0;
	padding-top: 0;

	.form__section {
		margin-bottom: .5em;
	}

	&__input,
	&__textarea {
		background-color: $contact-form-input-bg-color;
		border-color: $contact-form-input-bg-color;
		color: $contact-form-input-color;
		border-radius: 1px;
	}

	&__input {
		min-height: 55px;
	}

	&__info:first-child {
		margin: .55em 0 .9em;
	}
	.button.-primary{
		padding: 1em 2em;
		font-size: rem(14px);
		font-weight: bold;
		min-width: 180px;
		cursor: pointer;
		color: $contact-form-submit-color;
		background-color: $contact-form-submit-bg-color;
		border: solid 2px $contact-form-submit-border-color;
		border-radius: 0;
		@include link-over(&) {
			background-color: $contact-form-submit-color;
			color: $contact-form-submit-bg-color;
			box-shadow: none;
		}
	}
}
