
html {
	overflow-x: hidden;
	scroll-behavior: smooth;
}

body {
    margin: 0 auto;
    font-family: $font-body;
}

.centered {
    max-width: $max-width;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}