$gte-text-color: $search-color;
$gte-backgroud-color: transparent;

#google_translate_element {
	display: inline-block;
	position: relative;
    top: 1px;
    margin: 0 0 0 1em;
    align-self: center;

	&.field {
		background-color: $gte-backgroud-color;
	}

	.goog-te-gadget-simple {
		border: 0;
		width: 100%;
		padding-bottom: 1px;
        background-color: transparent;
        display: flex;
        align-items: center;

		&,
		& * {
            font-family: $font-body;
			font-size: rem(16px) !important;
		}

		.goog-te-gadget-icon{
			padding: 14.5px;
			background-size: 100%;
            background-repeat: no-repeat;
            order: 2;
		}

		[aria-haspopup] {
			margin-right: 0;
			display: inline-block;
			text-decoration: none!important;

			span {
				&:nth-of-type(1) {
					padding: 0 3px;
					display: inline-block;
                    color: $gte-text-color;
                    text-decoration: underline;
                    @include link-over(&){
                        text-decoration: none;
                    }
				}

				&:nth-of-type(2) {
					padding-right: 3px;
					border-left: 0!important;
				}

				&:nth-of-type(3) {
                    display: none;
					background: none;
					color: $gte-text-color!important;
				}
			}

			img {
				display: none;
			}
		}
	}
}

@include max(599px) {
	#google_translate_element,
	#goog-gt-tt,
	#goog-gt-tt + div > object,
	iframe.goog-te-menu-frame,
	iframe.goog-te-banner-frame {
		display: none!important;
	}
}
