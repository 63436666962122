@import "shared/colors";
//$cache-hash: '?v-'+unique-id() !global; // Unikátní hash pro cestu k obrázkům
/*==============================
	FONTS
==============================*/

$font-system-stack: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
$font-body: 'Mukta', sans-serif;
$font-headings: 'Saira Extra Condensed', $font-system-stack;
$font-sidebar-nav: 'Saira', sans-serif;

/*==============================
	COLORS
==============================*/

$color-base: #242424;
$color-brand: $main-nav-button-bg;
$color-secondary: $main-nav-button-bg-hover;
$color-light: #f6f2ef;
$color-dark: #292A2D;


/*==============================
	SIZES
==============================*/

$max-width: 1400px;
$grid-spacing: 40px;


/*==============================
	DEFAULTS
==============================*/

$default-document-link-color: $color-anchors;
$img-loc : "/images/local";


/* COMPONENT VARIABLES */

/*==============================
	FORMS
==============================*/

$form-active-color: #6b9aeb;
$form-active-text: #ffffff;

$form-item-standard-size: 42px;
$form-item-xl-size: 54px;
$form-item-border-color: #cccccc;
$form-item-border-width: 1px;
$form-item-border-radius: 4px;
$form-item-bg: #ffffff;
$form-item-fg: #2a2a2a;
$form-disabled-item-border-color: #e3e3e3;
$form-disabled-item-bg: #ebebeb;
$form-disabled-item-fg: lighten($form-item-fg, 10%);
$form-item-focus-shadow: rgba(0, 0, 0, .12);
$form-icon-color: #cccccc;


/*==============================
	BUTTONS
==============================*/

$button-radius: 4px;
$button-bg: $main-nav-button-bg-hover;
$button-fg: $main-nav-button-color-hover;
$button-primary-bg: $main-nav-button-bg;
$button-primary-fg: $main-nav-color;

/*==============================
	CALENDAR -PAGE
==============================*/

$cal-page-type-bg: #373737;
$cal-page-type-color: #ffffff;
$cal-page-type-selected-bg: $color-brand;
$cal-page-type-selected-color: #ffffff;

$cal-page-head-color: #373737;

$cal-page-day-bg: #f8f8f8;
$cal-page-day-color:#242424;
$cal-page-day-border-color:#c7c7c7;

$cal-page-day-in-prev-month-bg:#e7e7e7;
$cal-page-day-in-prev-month-color:#979797;

$cal-page-day-in-next-month-bg:$cal-page-day-in-prev-month-bg;
$cal-page-day-in-next-month-color:$cal-page-day-in-prev-month-color;

$cal-page-day-selected-border: $cal-page-type-selected-bg;
$cal-page-day-selected-color: $cal-page-type-selected-bg;

$cal-page-day-today-border:$color-secondary;
$cal-page-day-today-color:$color-secondary;
