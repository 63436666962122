
//Colors

$header-bg-color: #000000;
$header-color: #ffffff;

$main-nav-bg-color: #ffffff;
$main-nav-color: #ffffff;
$main-nav-button-bg: #61a42d;
$main-nav-button-color-hover: #000000;
$main-nav-button-bg-hover: #f8d03d;

$search-color: #222322;
$search-button-bg-color:#337800;

$blender-arrow-color: #337800;

$map-marker-color: #61a42d;

$contact-bg-color: #ffe25a;
$contact-color: #000000;
$more-button-color: #000000;
$more-button-bg-color: #f8d03d;
$more-button-border-color: #000000;

$social-networks-bg-color: #efc834;
$social-networks-color: #000000;

$warning-bg: rgba(#ffffff, 1);
$warning-color: #262626;

$guide-ico-bg-color: #c9180a;

$color-h2: #61a42d;
$color-h3: #61a42d;

$color-anchors: #337800;
$color-anchors-over: #337800;

$main-aside-bg-color: #61a42d;
$main-aside-color: #ffffff;

$news-bg: #f3f3f3;
$news-heading-color: $color-h2;
$news-date-color: #61a42d;
$news-calendar-color: #c6c6c6;
$news-title-color: #1d1d1d;
$news-color: #1d1d1d;
$news-left-line-bg-color: #efc834;
$news-right-line-bg-color: #61a42d;

$events-heading-color: $color-h2;
$events-calendar-color: #d3d3d3;
$events-date-color: #61a42d;
$events-date-slash-color: #61a42d;
$events-title-color: #1d1d1d;
$events-color: #1d1d1d;
$events-line-bg-color: #d3d3d3;

$board-heading-color: $color-h2;
$board-blur-bg: rgba(#ffffff, .95);
$board-title-color: #383838;
$board-color: #383838;
$board-calendar-from-color: #5b6365;
$board-calendar-datetime-color: #7c7c7c;
$board-more-bg-color: #61a42d;
$board-border-color: #ffffff;
$board-more-color: #ffffff;

$breadcrumbs-bg-color: $main-nav-bg-color;
$breadcrumbs-color: $search-color;
$breadcrumbs-color-active: $main-nav-button-bg;

$icon-links-bg-color: #ffffff;
$icon-links-title-color: #61a42d;
$icon-links-color: #1d1d1d;
$icon-links-ico-color: #61a42d;

$additional-bg-color: #efc834;
$additional-header-color: #363636;
$additional-color: #363636;

$contact-form-input-color: #1d1d1d;
$contact-form-input-bg-color: #ffffff;
$contact-form-submit-color: #1d1d1d;
$contact-form-submit-bg-color: #efc834;
$contact-form-submit-border-color: #1d1d1d;

$footer-bg-color: #ffffff;
$footer-color: #1d1d1d;

