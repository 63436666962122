

.abo {
    &__form{
        .label{
            position: absolute !important;
            left: -9999rem !important;
            top: auto;
            outline: 0;
            width: 0;
            height: 0;
            overflow: hidden;
        }
        .input{
            background-color: $contact-form-input-bg-color;
            border-color: $contact-form-input-bg-color;
            color: $contact-form-input-color;
            border-radius: 1px;
            min-height: 55px;
        }

        .gdpr-agreement{
            margin: 0;
        }

        .button.-primary{
				padding: 1em 2em;
				font-size: rem(14px);
				font-weight: bold;
				min-width: 180px;
				cursor: pointer;
				color: $contact-form-submit-color;
				background-color: $contact-form-submit-bg-color;
				border: solid 2px $contact-form-submit-border-color;
				border-radius: 0;
				@include link-over(&) {
					background-color: $contact-form-submit-color;
					color: $contact-form-submit-bg-color;
					box-shadow: none;
				}
			}
    }
}
