.list {
	list-style-type: none;
	padding: 0;
	margin: 0 0 2em;
	.list {
		padding: 0 0 0 2em;
	}

	&__item {
		margin: 0 0 .7em;
	}

	&__description {
		margin-top: .25em;
	}
}

.org-contacts {
	&__contact {
		span:not(:first-of-type) {
			&::before {
				background-color: $color-secondary;
			}
		}
	}
}
