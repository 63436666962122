
.breadcrumbs {
    width: 100%;
    background-color: $breadcrumbs-bg-color;
    text-align: right;
    color: $breadcrumbs-color;
    order: 15;
    padding: 2.2em 4%;
}

.breadcrumb-nav {
	margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
    font-family: $font-sidebar-nav;

	&__item {
		display: inline;


		&:not(.-active)::after {
            content: " \>";
            margin: 0 .5em;
            color: lighten($breadcrumbs-color, 50%);
		}
	}

	&__link {
		text-decoration: underline;
		display: inline-block;
	}

	@include link(".breadcrumb-nav__link") {
		color: $breadcrumbs-color;
		text-decoration: underline;
    }
    @include link(".breadcrumb-nav__link.-active") {
		pointer-events: none;
        text-decoration: none;
        color: $breadcrumbs-color-active;
	}

	@include link-over(".breadcrumb-nav__link") {
		text-decoration: none;
	}
}
